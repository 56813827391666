<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Buses</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Parent-Buses' }">Buses</router-link>
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content" v-if="bus">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Bus: {{ bus.name }}</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Tracking ID </label>
                <label class="col-md-9">{{ bus.bus_tracking_id }}</label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Capacity</label>

                <label class="col-md-9">{{ bus.capacity }}</label>
              </div>

              <!-- supervisors -->
              <div>
                <div class="form-group row">
                  <label
                    class="col-md-12 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> Supervisors
                  </label>
                </div>
                <div
                  class="form-group row"
                  v-if="bus.supervisors && bus.supervisors.length > 0"
                >
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Code</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(supervisor, i) in bus.supervisors"
                          :key="supervisor.id"
                        >
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ supervisor.code }}</td>
                          <td>{{ supervisor.fullname }}</td>
                          <td>{{ supervisor.email }}</td>
                          <td>{{ supervisor.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- Drivers -->
              <div>
                <div class="form-group row">
                  <label
                    class="col-md-12 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> Drivers
                  </label>
                </div>
                <div
                  class="form-group row"
                  v-if="bus.drivers && bus.drivers.length > 0"
                >
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Code</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(driver, i) in bus.drivers" :key="driver.id">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ driver.code }}</td>
                          <td>{{ driver.fullname }}</td>
                          <td>{{ driver.email }}</td>
                          <td>{{ driver.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- Map -->
              <div>
                <div class="form-group row">
                  <label
                    class="col-md-9 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> Current Location
                  </label>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary pull-right"
                      @click="refreshBusLocation()"
                    >
                      refresh
                    </button>
                  </div>
                </div>
                <div>
                  <div class="col-md-12" v-if="src">
                    <iframe style="width: 100%" :src="src"> </iframe>
                  </div>
                  <div class="col-md-12" v-else>
                    <p>No Data Available!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useBuses from "@/composables/buses";
import { onBeforeMount, ref } from "vue";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },

  setup(props) {
    const { getParentStdBus, getLastLocation, bus } = useBuses();
    const src = ref("");

    onBeforeMount(async () => {
      await getParentStdBus(props.id);
      await refreshBusLocation();
    });

    const setSRC = (location) => {
      src.value =
        "//maps.google.com/maps?q=" +
        location.mylat +
        "," +
        location.mylong +
        "&z=17&output=embed";
    };
    const refreshBusLocation = async () => {
      await getLastLocation(props.id).then((location) => {
        if (location) setSRC(location);
      });
    };
    return {
      src,
      bus,
      refreshBusLocation,
    };
  },
};
</script>
